<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 sm6 md6 lg6 xl6>
        <!-- <v-img
                  cover
                  alt="Wildwatch"
                  :src="getImgUrl(winStepValue(winStep).image)"
                /> -->
        <v-img
          src="../assets/images/authentication/login.jpg"
          fill-height
        ></v-img>
      </v-flex>
      <v-flex xs12 sm6 md6 lg6 xl6 align-self-center>
        <v-card elevation="0">
          <v-layout wrap justify-center>
            <v-flex xs12 md9 align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 text-center>
                  <span
                    style="
                      font-family: sofiaProSemiBold;
                      font-size: 20px;
                      cursor: pointer;
                      color: black;
                    "
                  >
                    HWC
                  </span>
                </v-flex>

                <v-flex xs12>
                  <v-layout wrap justify-center>
                    <v-flex xs6 pt-2 lg4 text-center>
                      <span
                        style="
                          font-family: sofiaProSemiBold;
                          font-size: 15px;
                          color: #000;
                        "
                      >
                        Log In
                      </span>
                      <v-progress-linear
                        height="4"
                        value="100"
                        color="#314755"
                      ></v-progress-linear>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex pt-6 xs12 text-left>
                  <v-layout wrap justify-center>
                    <v-flex xs10 pt-4>
                      <v-form @submit.prevent="validateInput">
                        <v-text-field
                          class="mainfont"
                          color="#717171"
                          placeholder="Username"
                          outlined
                          dense
                          style="font-family: sofiaProRegular"
                          v-model="emailId"
                          hide-details
                        >
                        </v-text-field>
                      </v-form>
                    </v-flex>
                    <v-flex xs10 pt-4>
                      <v-form @submit.prevent="validateInput">
                        <!-- <v-text-field
                                    color="#717171"
                                    placeholder="Password"
                                    outlined
                                    dense
                                    style="font-family: mainfont"
                                    v-model="password"
                                    hide-details
                                  >
                                  </v-text-field> -->
                        <v-text-field
                          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show3 ? 'text' : 'password'"
                          name="input-10-2"
                          color="#717171"
                          placeholder="Password"
                          v-model="password"
                          outlined
                          dense
                          style="font-family: sofiaProRegular"
                          class="input-group--focused"
                          @click:append="show3 = !show3"
                        ></v-text-field>
                      </v-form>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs10 pt-8 pb-2>
                  <v-btn
                    class="no-uppercase mainfont"
                    dark
                    block
                    color="#314755"
                    @click="validateInput"
                  >
                    LOGIN
                  </v-btn>
                </v-flex>
                <!-- <v-flex
                            style="cursor: pointer"
                            xs10
                            pt-4
                            pb-8
                            text-center
                            @click="$router.push('/register')"
                          >
                            <span style="font-family: mainfont"
                              >New user? Click here to register</span
                            >
                          </v-flex> -->
                <!-- <v-flex
                            style="cursor: pointer"
                            xs10
                            
                            pb-1
                            text-center
                            @click="$router.push('/forgotpassword')"
                          >
                            <span style="font-family: mainfont"
                              >Forgot Password?</span
                            >
                          </v-flex> -->
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
import store from "../store";
export default {
  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      otp: null,
      phone: null,
      emailId: null,
      password: null,
      show1: false,
      show2: true,
      show3: false,
      show4: false,
    };
  },
  mounted() {
    this.phone = this.$route.query.phone;
  },
  methods: {
    validateInput() {
      if (!this.emailId) {
        this.msg = "Please Provide Username.";
        this.showSnackBar = true;
        return;
      }if (!this.password) {
        this.msg = "Please Provide Password.";
        this.showSnackBar = true;
        return;
      } else {
        this.login();
      }
    },
    login() {
      axios({
        method: "post",
        url: "/platform/login/",
        data: {
          username: this.emailId,
          password: this.password,
        },
      })
        .then((response) => {
          if (response.data.heartbeat) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            //OLD WILDWATCH REQ
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("userRole", response.data.data.type);
            this.$store.commit("userData", response.data.userDetails);
            this.$store.commit("loginUser", response.data.data.token);
            this.$store.commit("userRole", response.data.data.type);
            this.$store.commit("userName", response.data.data.username);
            if (response.data.data.role == "official")
              this.$router.push("/officers");
            else if (response.data.data.type == "admin")
              this.$router.push("/admin");
            else if (response.data.data.type == "EmergencyUser")
              this.$router.push("/officers");
            else if (response.data.data.type == "RRT")
              this.$router.push("/officers");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
     
  